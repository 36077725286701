<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      We're developing a sustainable eco-village on the remote South Pacific
      island of Mauke in the Cook Islands.
    </p>
    <h3>Our Vision</h3>

    <p>1. To achieve "super abundance"</p>
    <p>2. To build "the house of dreams"</p>

    <h3>Videos</h3>
    <ul>
      <li>
        <p>What is Project Sunrise?</p>
      </li>
    </ul>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/JMeO9JdakqY"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>

    <h3>Support us 🙏❤️</h3>
    <ul>
      <li>
        <a
          href="https://opencollective.com/project-sunrise"
          target="_blank"
          rel="noopener"
          >opencollective.com/project-sunrise</a
        >
      </li>
    </ul>
    <!-- 
      <li>
        <a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a>
      </li>
      <li>
        <a
          href="https://github.com/vuejs/vue-devtools#vue-devtools"
          target="_blank"
          rel="noopener"
          >vue-devtools</a
        >
      </li>
      <li>
        <a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener"
          >vue-loader</a
        >
      </li>
      <li>
        <a
          href="https://github.com/vuejs/awesome-vue"
          target="_blank"
          rel="noopener"
          >awesome-vue</a
        >
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  // list-style-type: decimal;
  padding: 0;
}
li {
  // display: inline-block;
  // margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
